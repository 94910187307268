import moment from 'moment';
import { marked } from 'marked';
var globalFunction = {
    methods: {
        formatDate(date, format="dddd, D MMMM YYYY"){
            if (date) {
                const momentDate = moment(date.replace(/-/g, "/"));
                
                // Ambil nama hari dalam bahasa default
                const dayName = momentDate.format("dddd");
                
                // Terjemahkan nama hari menggunakan $t
                const translatedDay = this.$t(dayName);

                // Format tanggal sesuai format, lalu ganti nama hari dengan yang diterjemahkan
                return momentDate.format(format).replace(dayName, translatedDay);
            }
            return '-';
        },
        changeToMarked(string="") {
            return marked(string || "");
        }
    },
}

export default globalFunction